import { useEffect, useState } from 'react';
import '/assets/styles/sections/header/partials/search-desktop.scss';

// Partials
import Icon from '@wearetla/tla-essentials-tools/partials/icon';

// Context
import { useNavigator, useParams } from '@wearetla/tla-essentials-tools/utilities/navigator';

const Search = () => {
	const { term: paramTerm } = useParams('search');
	const [searchTerm, setSearchTerm] = useState('');
	const { redirect } = useNavigator();

	const submit = (e) => {
		e.preventDefault();
		redirect('search', { term: searchTerm });
	}

	useEffect(() => {
		if(paramTerm?.length) {
			setSearchTerm(paramTerm);
		}
		else {
			setSearchTerm('')
		}
	}, [paramTerm])

	return (
		<form onSubmit={submit} className="header-search">
			<Icon name="search" className="search-icon" />
			<input
				type="text"
				className="search-input"
				onChange={(e) => {
					setSearchTerm(e.target.value);
				}}
				value={searchTerm}
				placeholder="Ara" />
		</form>
	)
}

export default Search;